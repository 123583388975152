<template>
  <div class="join_step3">
    <div>
      <h1>{{joinStatus}}</h1>
    </div>
    <div class="bond_btn" v-if="bondBtn">
      <router-link to="/store/join/step_4">
        <el-button type="text">缴纳保证金</el-button>
      </router-link>
    </div>
    <router-link to="/">
      <el-button type="primary">返回首页</el-button>
    </router-link>
  </div>
</template>

<script>
  import { joinSpeedApi } from '@/api/api'
  export default {
    data() {
      return {
        joinStatus: '',
        bondBtn: false
      }
    },
    created() {
      this.joinSpeed()
    },
    methods: {
      joinSpeed() {
        this.$emit('getActiveStep', 2)
        joinSpeedApi().then(res => {
          console.log(res)
          if (res.data.status === 90004) return this.$router.go(-1)
          this.joinStatus = res.data.result.joinin_state
          if (this.joinStatus === '资料审核成功，待缴费') this.bondBtn = true
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .join_step3 {
    line-height: 150px;

    .bond_btn {
      height: 30px;
      line-height: 30px;
    }

    h1 {
      font-size: 50px;
    }

    a {
      color: #fff;
    }
  }
</style>